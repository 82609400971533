import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../core/services/authentication.service';
import { UserModel } from '../shared/auth/user.model';
import { AppLocaleStorageService } from '../shared/util/local-storage.service';
import Gleap from 'gleap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginForm') public loginForm: NgForm;
  public user: UserModel;
  public language: string;
  public errorMessage: string;
  public loading: boolean = false;
  public passwordInputType: boolean = true;

  constructor(
    public alss: AppLocaleStorageService,
    public translateService: TranslateService,
    protected authenticationService: AuthenticationService,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.authenticationService.checkServerAuthentication('/login').subscribe({
      next: () => {
        if (this.router.url.includes('/login')) {
          this.router.navigate(['panel', 'dashboard']);
        }
      }
    });
    this.user = new UserModel();
    if (this.alss.getItem('language')) {
      this.language = this.alss.getItem('language');
    } else {
      this.language = 'de';
    }

    if (!environment.production) {
      this.user.username = 'fnb@rauschenberger-catering.de';
      this.user.password = 'test3412!!';
    }
  }

  onLogin(): void {
    this.login();
  }

  keyDownFunction(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.onLogin();
    }
  }

  login(): void {
    this.loading = true;

    if (this.loginForm.controls.username.value == '' || this.loginForm.controls.password.value == '') {
      this.loading = false;
      return;
    }

    if (this.loginForm.controls.username.valid && this.loginForm.controls.password.valid) {
      this.authenticationService
        .authenticate(
          this.loginForm.controls.username.value,
          this.loginForm.controls.password.value,
          this.loginForm.controls.rememberMe.value
        )
        .subscribe({
          next: (res: any) => {
            Gleap.trackEvent('User signed in');

            this.router.navigate(['panel', 'dashboard']);
          },
          error: () => {
            this.loginForm.form.setErrors({ custom: 'shared.loginPage.validationMessages.noMatch' });
            this.loading = false;
            return;
          }
        });
    }
  }

  languageChange(lang: string) {
    this.alss.setItem('language', lang);
    this.translateService.use(lang);
    this.language = lang;
  }

  public showHidePassword() {
    this.passwordInputType = !this.passwordInputType;
  }
}
